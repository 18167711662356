import RoomSection from "./tools/roomsection";
import ConfortSection from "./tools/confort";



const Rooms = () => {
    return (
        
        <main>
            <RoomSection/>
            <ConfortSection/>
        </main>
        
        )}

export default Rooms
